import { useState } from "react"
import prodotti_fetch from "../fetch/prodotti_fetch"
import classProdSezzione from "../fetch/classProdSezzione"
import InfoModal from "../riutilizabili/InfoModal"
import { useNavigate } from "react-router"
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione"
import prodSituazione_fetch from "../fetch/prodSituazione_fetch"
import ReadCampiBolla from "../bolla/sezioni/ReadCampiBolla"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faFileLines } from "@fortawesome/free-solid-svg-icons"
const { format } = require('date-fns');



export default function ViewProdPopUp({
    prodAnId ,
    icona ,
    classiButton=''
}) {
    const [datiProd , setDatiProd] = useState(false)
    const [prods , setProds] = useState(false)
    const navigate = useNavigate();

        const [pageTot , setPageTot] = useState(0);
        const [pageSize , setPageSize] = useState(10);
        const [page , setPage] = useState(1);
        const [azzeraVar , setAzzeraVar] = useState(0)
        const [fu , setFu] = useState(0)
        const [inputsFiltra , setInputsFiltra] = useState({})
        const [viewBolla , setViewBolla] = useState(false);
        const [viewBollaUscita , setViewBollaUscita] = useState(false);
    
    const visualizzaProd = async () => {
        const datiProd = await prodotti_fetch.get({id : prodAnId})
        console.log(datiProd , ' datiProd',prodAnId);
        
        const array = datiProd.data[0];
        let newArray = {}
        const datiPrendere = [
            'nome', 
            'descrizione', 
            'infoCategoria' ,
            'infoSottoCategoria' ,
            'mittente' ,
            'codBarre' ,
            'prezzoVendita' ,
            'costo' ,
            'rifInterno' ,
            'note' ,
            'asin' ,
            'qtMin' ,
            'automaticReorder' ,
            'posizioneMg' ,
            'costoCent' ,
        ]
        for (let index = 0; index < Object.keys(array).length; index++) {
            const element = Object.keys(array)[index];
            //console.log(element , ' element');
            if (datiPrendere.includes(element)) {
                if (element === 'infoCategoria') {
                    newArray['categoria'] = array[element].nome
                }else if (element === 'infoSottoCategoria') {
                    newArray['sotto Categoria'] = array[element]?.nome
                }else if (element === 'mittente') {
                    newArray['fornitore'] = array[element]?.viewName
                } if (element === 'codBarre') {
                    newArray['Codice a barre'] = array[element]
                } if (element === 'prezzoVendita') {
                    newArray['prezzo di vendita'] = array[element]
                } if (element === 'rifInterno') {
                    newArray['riferimento interno'] = array[element]
                } if (element === 'qtMin') {
                    newArray['quantita minima'] = array[element]
                }if (element === 'descrizione') {
                    newArray['descrizione'] = array[element]
                } if (element === 'automaticReorder') {
                    newArray['Riordino automatico'] = array[element] ? 'si' : 'no'
                }  if (element === 'posizioneMg') {
                }if (element === 'costoCent') {
                    newArray['costo'] = (array[element]/100)+' €'
                } {
                    if (!datiPrendere.includes(element)) {
                        newArray[element] = array[element]
                        
                    }
                }
            }
        }     
        setDatiProd(newArray)
        const prods = await classProdSezzione.get({prodottoId: prodAnId , pageSize , page , orderId :'ASC'})
        //setProds(prods.data.data)
        setPageTot(prods.data.pageTot)

        const movimenti = await prodSituazione_fetch.readStoricoProd({prodottoId: prodAnId})
        console.log(movimenti.data , ' movimenti');
        setProds(movimenti.data)
        
    }

    console.log(datiProd , prods , ' datiProd , prods');
    

    const filtra = async (param) => {
        await classProdSezzione.get({prodottoId: prodAnId , pageSize , page , orderId :'ASC' , ...param})
        .then(response => {
            //console.log(response.data , ' response.data');
            setProds(response.data.data)
            setPageTot(response.data.pageTot)
            setFu(fu+1)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }

    
    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtra({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }

    const formato = "dd/MM/yyyy HH:mm"; 
    const columns = [
        {
            label: 'Descrizione',
            nome: 'descrizione',
            altCol: (item)=> !!item?.infoProd?.descrizione ? item?.infoProd?.descrizione : item?.Prodotti?.descrizione
        },
        {
            label: 'Quantità',
            nome: 'quantita',
            altCol: (item)=> (item?.bollaUscita ? '-':'+')+(!!item?.quantita ? item?.quantita : item?.qta)
        },
        {
            label: 'Fornitore/CLiente',
            nome: 'Posizione',
            altCol: (item)=> {
                console.log(item , ' item');
                
                return !item?.bollaUscita ? item.bollaEntrata.mittente.viewName : (!!item?.bollaUscita ? item?.bollaUscita.mittente.viewName : '')
            }
        },
        {
            label: 'DDT',
            nome: 'BollaEntrata',
            altCol: (item)=> {
                return (
                    !item?.bollaUscita ? 
                        <span className="d-flex justify-content-center">
                            <span>
                                {item?.bollaEntrata?.numero+' - '+ (!!item?.bollaEntrata && format(item?.bollaEntrata?.dataCreate, formato))}
                            </span>
                            <button onClick={()=>setViewBolla(item.bollaEntrata)} className="btn py-0 my-0 pb-1">
                                <FontAwesomeIcon icon={faEye} />
                            </button>
                        </span>
                    : 
                    <span className="d-flex justify-content-center">
                        {!!item?.bollaUscita ? 
                        <span>
                            {item?.bollaUscita?.idProg  + '/' + item?.bollaUscita?.annoId+' - '+( !!item?.bollaUscita && format(item?.bollaUscita?.dataCreate, formato))}
                        </span>
                            : ''
                        }
                         <button onClick={()=>setViewBollaUscita(item.bollaUscita)} className="btn py-0 my-0 pb-1">
                                <FontAwesomeIcon icon={faEye} />
                            </button>
                    </span>
                    
                )
            }
        },
        // {
        //     label: 'BollaUscita',
        //     nome: 'BollaUscita',
        //     altCol: (item)=> {
        //         return !!item?.bollaUscita ? (item?.bollaUscita?.id + '/' + item?.bollaUscita?.annoId+' - '+( !!item?.bollaUscita && format(item?.bollaUscita?.dataCreate, formato))) : ''
        //     }
        // },
    ]
    
       const convertiData = (data) => {
                //console.log(format(new Date(data) ,'dd-MM-yyyy hh:mm') , ' Date ' , data);
                //console.log(data ,' Date 2' , (data+'').indexOf('Z'));
                if ((data+'').indexOf('Z') !== -1) {
                    return format(new Date(data) ,'dd-MM-yyyy hh:mm') 
                }
                return format(new Date(data) ,'dd-MM-yyyy') 
        }

    console.log(datiProd , ' datiProd');
    
            
    return(
        <>
            <button className={"btn "+classiButton} onClick={visualizzaProd}>
                {icona}
            </button>

            {!!prods &&<InfoModal
                title={'Prodotto '+datiProd.descrizione}
                text={<div>
                    <div className="d-flex flex-wrap border border-primary rounded m-2">
                        {Object.keys(datiProd).map((item , key) => {
                                    console.log(item, ' itemc ', datiProd[item]);
                                    return(
                                        <div key={key} className="p-2 d-flex flex-column  m-4" >
                                            <span className='fw-bold' > {item} </span>
                                            {datiProd[item] !== null ? datiProd[item] : ' '}
                                        </div>
                                    )
                                })}
                    </div>
                    <div className='p-3 pt-5 pe-4'>
                        {/* <TabellaPaginazione
                            colonne={columns}
                            dati={prods}
                            totalePag={pageTot}
                            azzera={azzeraVar}
                            impaginazione={impaginazione}
                            fu={fu}
                        /> */}
                        <TabellaPaginazione
                            colonne={columns}
                            dati={prods}
                            impaginazione={null}
                        />
                    </div>
                    
                </div>}
                canClose
                handleClose={()=>setProds(false)}
                classi=' modal-xxl '
            />}

            {!!viewBolla && 
                <InfoModal
                    size='xl'
                    title={'Ddt n° ' +viewBolla.numero}
                    text={
                        <ReadCampiBolla inputs={viewBolla} infoAddView={false}/>
                    }
                    canClose
                    handleClose={()=>setViewBolla(false)}
                    className = 'modal-lg'
                />
            }

            {!!viewBollaUscita && 
                <InfoModal
                    title={ 
                    <h3 className="text-white d-flex align-items-center ps-3" >
                        <FontAwesomeIcon className="me-3 text-white" icon={faFileLines} />
                        DDT N° { viewBollaUscita?.id+'/'+viewBollaUscita?.annoId+(!!viewBollaUscita?.codiceMag ? ' - '+viewBollaUscita.codiceMag : '') + ' del ' +convertiData(viewBollaUscita?.dataFattura) }
                    </h3>
                    }
                    text={
                        <ReadCampiBolla inputs={viewBollaUscita} cliente={true} />
                    }
                    canClose
                    handleClose={()=>setViewBollaUscita(false)}
                    className = 'modal-xl'
                />
            }
        </>
    )
}